import Urls from "../urls";

export default class AxiosUtils {
  static axiosConfigConstructor = (
    method: string,
    endpoint: string,
    data: any,
    headers?: any,
    params?: any
  ) => {
    console.log("Master_Key", Urls.MASTERKEY);
    console.log("Application_Id", Urls.APPLICATIONID);
    console.log("Urls", Urls.baseUrl);
    return {
      method: method,
      url: endpoint,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
        
        ...headers,
      },
      data: data,
      params: params,
    };
  };
}
