import { createContext, useContext, useEffect, useState } from "react";
import Constants from "../utils/constants";

interface AppWrapperProps {
  state: {
    isMobile: boolean;
    stores: any;
    offers: any;
    formData: any;
    selectedFormDataValue: any;
    isEditing: boolean;
    selectedAgreement: any;
    signingState: boolean;
  };
  addToStore: Function;
  addToOffer: Function;
  clearStores: Function;
  clearOffers: Function;
  updateSelectedFormDataValue: Function;
  updateFormData: Function;
  setStores: Function;
  setOffers: Function;
  setCustomStore: Function;
  setCustomOffers: Function;
  setIsEditing: Function;
  setselectedAgreement: Function;
  setSigningState: Function;
}
const AppContext = createContext<AppWrapperProps>(undefined);

export function AppWrapper({ children, ...props }: any) {
  const [isMobile, setIsMobile] = useState(false);
  const [stores, setStores] = useState([]);
  const [offers, setOffers] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedFormDataValue, setSelectedFormDataValue] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [signingState, setSigningState] = useState(false);
  const [selectedAgreement, setselectedAgreement] = useState({});
  useEffect(() => {
    setIsMobile((window?.innerWidth ?? 0) <= Constants.MOBILE_SCREEN_WIDTH);
    const debouncedResizeHandler = debounce(() => {
      console.log("***** debounced resize"); // See the cool difference in console
      setIsMobile((window?.innerWidth ?? 0) <= Constants.MOBILE_SCREEN_WIDTH);
    }, 100);
    window.addEventListener("resize", debouncedResizeHandler);
    return () => window.removeEventListener("resize", debouncedResizeHandler);
  }, []);

  const addToStore = (storeItem) => {
    setStores([...stores, storeItem]);
  };

  const addToOffer = (offerItem) => {
    setOffers([...offers, offerItem]);
  };

  const updateSelectedFormDataValue = (formData) => {
    setSelectedFormDataValue({ ...formData });
  };

  const updateFormData = (formData) => {
    console.log("Data Hai Bhai", formData);
    setFormData({ ...formData });
  };

  const clearStores = () => {
    setStores([]);
  };

  const clearOffers = () => {
    setOffers([]);
  };

  const setCustomStore = (store: any[]) => {
    setStores([...store]);
  };

  const setCustomOffers = (offers: any[]) => {
    setOffers([...offers]);
  };

  const updateIsEditing = (value: boolean) => {
    setIsEditing(value);
  };

  const updateSigningState = (value: boolean) => {
    setSigningState(value);
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          isMobile: isMobile,
          stores: stores,
          offers: offers,
          formData: formData,
          selectedFormDataValue: selectedFormDataValue,
          isEditing: isEditing,
          selectedAgreement: selectedAgreement,
          signingState: signingState,
        },
        addToStore: addToStore,
        addToOffer: addToOffer,
        updateFormData: updateFormData,
        updateSelectedFormDataValue: updateSelectedFormDataValue,
        setStores: setStores,
        setOffers: setOffers,
        clearOffers: clearOffers,
        clearStores: clearStores,
        setCustomStore: setCustomStore,
        setCustomOffers: setCustomOffers,
        setIsEditing: updateIsEditing,
        setSigningState: updateSigningState,
        setselectedAgreement: setselectedAgreement,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}
