import { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext(undefined);

export function UserWrapper({ children, ...props }: any) {
  const [user, setUser] = useState({});
  const [userPermission, setUserPermission] = useState({});

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") == "true") {
      const updatedUserPermission = JSON.parse(
        localStorage.getItem("userPermissions")
      );
      setUserPermission({ ...updatedUserPermission });
    }

    if (localStorage.getItem("isLoggedIn") == "true") {
      const updatedUser = JSON.parse(localStorage.getItem("user"));
      setUser({ ...updatedUser });
    }
  }, []);

  const updateUser = (newUser: any) => {
    setUser({ ...newUser });
  };

  const updateUserPermission = (newPermission) => {
    setUserPermission({ ...newPermission });
  };
  return (
    <UserContext.Provider
      value={{
        state: {
          user,
          userPermission,
        },
        updateUser: updateUser,
        updateUserPermission: updateUserPermission,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
