import React from "react";
import thriweLogo from "../../assets/images/thriwe-logo.png";
import "./style.css";
import { Dropdown } from "react-bootstrap";
import profileLogo from "../../assets/images/users/user-dummy-img.jpg";
import { useNavigate } from "react-router-dom";
import { BsList } from "react-icons/bs";

const NavBar = () => {
  const navigate = useNavigate();

  const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

  return (
    <nav className="navbar sticky-top">
      <a href="/">
        <img
          style={{ marginLeft: "22px" }}
          className="thriwe-logo"
          src={thriweLogo}
          width="80"
          height="30"
        />
      </a>
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          style={{
            margin: 0,
            padding: 0,
            marginRight: "18px",
            outline: "none !important",
            border: "none",
          }}
        >
          <div>
            <BsList style={{ fontSize: "20px", color: "#878a99" }} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropMenu">
          {/* <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
          <Dropdown.Item eventKey="3" active>
            Active Item
          </Dropdown.Item>
          <Dropdown.Divider /> */}
          <Dropdown.Item
            eventKey="6"
            onClick={() => {
              navigate("/summary");
            }}
          >
            Summay
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="5"
            onClick={() => {
              navigate("/brief");
            }}
          >
            Brief
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="5"
            onClick={() => {
              navigate("/get-data");
            }}
          >
            Download Data
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="6"
            onClick={() => {
              navigate("/audit");
            }}
          >
            Audit
          </Dropdown.Item>
          {(userPermissions?.SalesHandler || userPermissions?.checkAudit) && (
            <Dropdown.Item
              eventKey="11"
              onClick={() => {
                navigate("/audit-list");
              }}
            >
              Delete Audit
            </Dropdown.Item>
          )}

          <Dropdown.Item
            eventKey="4"
            onClick={() => {
              localStorage.setItem("isLoggedIn", "false");
              localStorage.removeItem("merchant");
              localStorage.removeItem("brand");
              localStorage.removeItem("stores");
              localStorage.removeItem("offers");
              navigate("/login");
            }}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </nav>
  );
};

export default NavBar;
