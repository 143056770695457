import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

const PopupLogs = ({ data, onClose }) => {
  const [fieldNamesForMerchant, setFieldNamesForMerchant] = useState([]);
  const [fieldNamesForBrand, setFieldNamesForBrand] = useState([]);

  useEffect(() => {
    if (data?.merchantLogsList.length > 0) {
      const merchantFields = Object.keys(data.merchantLogsList[0]).filter(
        (fieldName) =>
          fieldName !== "objectId" &&
          fieldName !== "collectionName" &&
          fieldName !== "collectionId" &&
          fieldName !== "createdAt" &&
          fieldName !== "updatedAt"
      );
      setFieldNamesForMerchant(merchantFields);
    }

    if (data?.brandLogsList.length > 0) {
      const brandFields = Object.keys(data.brandLogsList[0]).filter(
        (fieldName) =>
          fieldName !== "objectId" &&
          fieldName !== "collectionName" &&
          fieldName !== "collectionId" &&
          fieldName !== "createdAt" &&
          fieldName !== "updatedAt"
      );
      setFieldNamesForBrand(brandFields);
    }
  }, [data]);

  console.log("fieldNamesForMerchant", fieldNamesForMerchant);
  console.log("fieldNamesForBrand", fieldNamesForBrand);

  const convertCamelCaseToWords = (inputString: string) => {
    // Convert camelCase or snake_case or kebab-case to title case with spaces
    let stringWithSpaces = inputString
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/([a-z])([A-Z])/g, "$1 $2"); // Add space between camelCase

    // Capitalize the first letter of the resulting string
    stringWithSpaces =
      stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);

    return stringWithSpaces;
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <button className="popup-close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        {data?.agreementLogsList?.length !== 0 && (
          <>
            <table
              style={{
                border: "1px solid #ddd",
                margin: "20px",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      background: "#f5f5f5",
                      fontWeight: "bold",
                      padding: "10px",
                    }}
                  >
                    Rejection Remark
                  </td>
                  <td style={{ padding: "10px" }}>
                    {data?.agreementLogsList[0]["agreementRemarks"]}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      background: "#f5f5f5",
                      fontWeight: "bold",
                      padding: "10px",
                    }}
                  >
                    Reason
                  </td>
                  <td style={{ padding: "10px" }}>
                    {data?.agreementLogsList[0]["reason"]}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {data?.merchantLogsList.length !== 0 && (
          <>
            <h4>Merchant</h4>
            <div style={{ overflowX: "scroll" }}>
              <Table>
                {data?.merchantLogsList.length !== 0 ? (
                  <thead>
                    <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                      <th>S.No</th>
                      <th>Field Name </th>
                      <th>Initial Value</th>
                      <th>Second Last Value</th>
                      <th>Current Value</th>
                      <th>Changed By</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                ) : null}
                <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                  {fieldNamesForMerchant?.length > 0 &&
                    fieldNamesForMerchant?.map((fieldName, index) => (
                      <tr key={fieldName}>
                        <td>{index + 1}</td>
                        <td>{convertCamelCaseToWords(fieldName)}</td>
                        <td>
                          {data?.merchantLogsList[0][fieldName][0].value ||
                            "N/A"}
                        </td>
                        <td>
                          {data?.merchantLogsList[0][fieldName].length > 2
                            ? data?.merchantLogsList[0][fieldName][
                                data?.merchantLogsList[0][fieldName].length - 2
                              ].value
                            : "NA"}
                        </td>
                        <td>
                          {data?.merchantLogsList[0][fieldName][
                            data?.merchantLogsList[0][fieldName].length - 1
                          ].value || "N/A"}
                        </td>
                        <td>
                          {data?.merchantLogsList[0][fieldName][
                            data?.merchantLogsList[0][fieldName].length - 1
                          ].name || "N/A"}
                        </td>
                        <td>
                          {data?.merchantLogsList[0][fieldName][
                            data?.merchantLogsList[0][fieldName].length - 1
                          ].time || "N/A"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>{" "}
          </>
        )}
        {data?.brandLogsList.length !== 0 && (
          <>
            <h4>Brand</h4>
            <div style={{ overflowX: "scroll" }}>
              <Table>
                {data?.brandLogsList.length !== 0 ? (
                  <thead>
                    <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                      <th>S.No</th>
                      <th>Field Name </th>
                      <th>Initial Value</th>
                      <th>Second Last Value</th>
                      <th>Current Value</th>
                      <th>Changed By</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                ) : null}
                <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                  {fieldNamesForBrand?.length > 0 &&
                    fieldNamesForBrand?.map((fieldName, index) => (
                      <tr key={fieldName}>
                        <td>{index + 1}</td>
                        <td>{convertCamelCaseToWords(fieldName)}</td>
                        <td>
                          {data?.brandLogsList[0][fieldName][0].value || "N/A"}
                        </td>
                        <td>
                          {data?.brandLogsList[0][fieldName].length > 2
                            ? data?.brandLogsList[0][fieldName][
                                data?.brandLogsList[0][fieldName].length - 2
                              ].value
                            : "NA"}
                        </td>
                        <td>
                          {data?.brandLogsList[0][fieldName][
                            data?.brandLogsList[0][fieldName].length - 1
                          ].value || "N/A"}
                        </td>
                        <td>
                          {data?.brandLogsList[0][fieldName][
                            data?.brandLogsList[0][fieldName].length - 1
                          ].name || "N/A"}
                        </td>
                        <td>
                          {data?.brandLogsList[0][fieldName][
                            data?.brandLogsList[0][fieldName].length - 1
                          ].time || "N/A"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>{" "}
          </>
        )}
        {data?.storeLogsList.length !== 0 && (
          <div style={{ overflowX: "scroll" }}>
            {data?.storeLogsList.map((logs: any, logsIndex: number) => (
              <div key={logsIndex}>
                <h4>Store: {logs["storeName"]}</h4>
                <Table>
                  <thead>
                    <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                      <th>S.No</th>
                      <th>Field Name</th>
                      <th>Initial Value</th>
                      <th>Second Last Value</th>
                      <th>Current Value</th>
                      <th>Changed By </th>
                      <th>Date </th>
                    </tr>
                  </thead>
                  <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                    {logs &&
                      Object.keys(logs)
                        .filter(
                          (fieldName) =>
                            fieldName !== "objectId" &&
                            fieldName !== "collectionName" &&
                            fieldName !== "collectionId" &&
                            fieldName !== "createdAt" &&
                            fieldName !== "updatedAt" &&
                            fieldName !== "storeName"
                        )
                        .map((fieldName, fieldNameIndex) => (
                          <tr key={fieldNameIndex}>
                            <td>{fieldNameIndex + 1}</td>
                            <td>{convertCamelCaseToWords(fieldName)}</td>
                            <td>
                              {fieldName === "tidANDMID"
                                ? logs[fieldName][0].value.map(
                                    (item: any, itemIndex: number) => (
                                      <div key={itemIndex}>
                                        <p>
                                          TID: {item.tid}, MID: {item.mid}
                                        </p>
                                        <p>
                                          Bank Machine: {item.bankMachineName}
                                        </p>
                                        <p>MDR: {item.mdr}</p>
                                      </div>
                                    )
                                  )
                                : logs[fieldName][0]?.value || "N/A"}
                            </td>
                            <td>
                              {logs[fieldName].length > 2
                                ? fieldName === "tidANDMID"
                                  ? logs[fieldName][
                                      logs[fieldName].length - 2
                                    ].value.map(
                                      (item: any, itemIndex: number) => (
                                        <div key={itemIndex}>
                                          <p>
                                            TID: {item.tid}, MID: {item.mid}
                                          </p>
                                          <p>
                                            Bank Machine: {item.bankMachineName}
                                          </p>
                                          <p>MDR: {item.mdr}</p>
                                        </div>
                                      )
                                    )
                                  : logs[fieldName][logs[fieldName].length - 2]
                                      ?.value
                                : "N/A"}
                            </td>
                            <td>
                              {fieldName === "tidANDMID"
                                ? logs[fieldName][
                                    logs[fieldName].length - 1
                                  ].value.map(
                                    (item: any, itemIndex: number) => (
                                      <div key={itemIndex}>
                                        <p>
                                          TID: {item.tid}, MID: {item.mid}
                                        </p>
                                        <p>
                                          Bank Machine: {item.bankMachineName}
                                        </p>
                                        <p>MDR: {item.mdr}</p>
                                      </div>
                                    )
                                  )
                                : logs[fieldName][logs[fieldName].length - 1]
                                    ?.value}
                            </td>
                            <td>
                              {logs[fieldName][logs[fieldName].length - 1]
                                ?.name || "N/A"}
                            </td>
                            <td>
                              {logs[fieldName][logs[fieldName].length - 1]
                                ?.time || "N/A"}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            ))}
          </div>
        )}
        {data?.offersLogsList.length !== 0 && (
          <div style={{ overflowX: "scroll" }}>
            {data?.offersLogsList.map((logs: any, logsIndex: number) => (
              <div key={logsIndex}>
                <h4>Offer: {logs["offerName"]}</h4>
                <Table>
                  <thead>
                    <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                      <th>S.No</th>
                      <th>Field Name</th>
                      <th>Initial Value</th>
                      <th>Second Last Value</th>
                      <th>Current Value</th>
                      <th>Changed By </th>
                      <th>Date </th>
                    </tr>
                  </thead>
                  <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                    {logs &&
                      Object.keys(logs)
                        .filter(
                          (fieldName) =>
                            fieldName !== "objectId" &&
                            fieldName !== "collectionName" &&
                            fieldName !== "collectionId" &&
                            fieldName !== "createdAt" &&
                            fieldName !== "updatedAt" &&
                            fieldName !== "offerName"
                        )
                        .map((fieldName, fieldNameIndex) => (
                          <tr key={fieldNameIndex}>
                            <td>{fieldNameIndex + 1}</td>
                            <td>{convertCamelCaseToWords(fieldName)}</td>
                            <td>
                              {fieldName === "offerExclusion" ||
                              fieldName === "termOfUse" ||
                              fieldName === "redemption"
                                ? logs[fieldName][0]?.value?.value || "N/A"
                                : logs[fieldName][0]?.value || "N/A"}
                            </td>
                            <td>
                              {logs[fieldName].length > 2
                                ? fieldName === "offerExclusion" ||
                                  fieldName === "termOfUse" ||
                                  fieldName === "redemption"
                                  ? logs[fieldName][logs[fieldName]?.length - 2]
                                      ?.value?.value || "N/A"
                                  : logs[fieldName][logs[fieldName]?.length - 2]
                                      ?.value || "N/A"
                                : "N/A"}
                            </td>
                            <td>
                              {fieldName === "offerExclusion" ||
                              fieldName === "termOfUse" ||
                              fieldName === "redemption"
                                ? logs[fieldName][logs[fieldName].length - 1]
                                    ?.value?.value || "N/A"
                                : logs[fieldName][logs[fieldName].length - 1]
                                    ?.value || "N/A"}
                            </td>
                            <td>
                              {logs[fieldName][logs[fieldName].length - 1]
                                ?.name || "N/A"}
                            </td>
                            <td>
                              {logs[fieldName][logs[fieldName].length - 1]
                                ?.time || "N/A"}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupLogs;
